@import url('satoshi.css');
@import url('heming.css');

/* akqa fonts */
@import url('clash-grotesk.css');
@import url('erode.css');
@import url('heming.css');
@import url('space-mono.css');
@import url('alt-riveria.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    height: 100%;
}

body {
    @apply text-black font-brand-default;
    scroll-behavior: smooth;
}

body.mobile-nav-showing {
    @apply overflow-hidden;
}

.no-scroll {
    overflow: hidden;
}

/* colors for toastify component */
:root {
    --toastify-color-info: #1b1dd1 !important;
    --toastify-color-success: #02d8c3 !important;
    --toastify-color-error: #d80275 !important;
    --toastify-color-warning: #f1c40f !important;
}

/* remove the number flipers from number fields */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

/* styles for fireworks */
.pyro > .before,
.pyro > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow:
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff,
        0 0 #fff;
    -moz-animation:
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -webkit-animation:
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -o-animation:
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    -ms-animation:
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
    animation:
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards;
}

.pyro > .after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
    to {
        box-shadow:
            -243px -258.6666666667px #fffb00,
            -11px -306.6666666667px #ff7b00,
            130px -92.6666666667px #00fff2,
            45px -356.6666666667px #00ff44,
            -142px 61.3333333333px #001eff,
            64px -139.6666666667px #84ff00,
            -8px -95.6666666667px #6aff00,
            -188px -110.6666666667px #ff00ea,
            234px -139.6666666667px #5900ff,
            18px -274.6666666667px #00aeff,
            -138px -277.6666666667px #ffdd00,
            41px 70.3333333333px #ff0051,
            -221px -318.6666666667px #00b3ff,
            174px -94.6666666667px #62ff00,
            76px -47.6666666667px #b7ff00,
            208px -102.6666666667px #0099ff,
            -173px -249.6666666667px #ff007b,
            -19px -365.6666666667px #0040ff,
            -249px 8.3333333333px #ff00aa,
            187px -46.6666666667px #ff8400,
            -56px -405.6666666667px #ff00ee,
            -91px 4.3333333333px #ffa200,
            77px -347.6666666667px #00ffaa,
            5px -387.6666666667px #ff001a,
            -132px -33.6666666667px #0091ff,
            -155px -125.6666666667px #6200ff,
            -90px -99.6666666667px #1100ff,
            36px -319.6666666667px #00ff5e,
            78px -247.6666666667px #0015ff,
            -71px -286.6666666667px #0011ff,
            92px -109.6666666667px #ffa200,
            -125px -97.6666666667px #f700ff,
            -140px -342.6666666667px #00ff2b,
            42px -239.6666666667px #9d00ff,
            73px -52.6666666667px #e1ff00,
            -224px -85.6666666667px red,
            -193px -12.6666666667px #d900ff,
            -5px -217.6666666667px #4400ff,
            -9px -19.6666666667px #1500ff,
            -238px -338.6666666667px #8cff00,
            -230px -353.6666666667px #ff5100,
            32px -194.6666666667px #ff3300,
            25px -142.6666666667px #0900ff,
            78px -395.6666666667px #ff4d00,
            -170px -206.6666666667px #00bbff,
            -96px -191.6666666667px #7700ff,
            135px 62.3333333333px #ff0099,
            -64px -315.6666666667px #ffe600,
            -186px -3.6666666667px #00ffa6,
            117px -254.6666666667px #ff5500,
            -248px -74.6666666667px #c800ff;
    }
}
@-moz-keyframes bang {
    to {
        box-shadow:
            -243px -258.6666666667px #fffb00,
            -11px -306.6666666667px #ff7b00,
            130px -92.6666666667px #00fff2,
            45px -356.6666666667px #00ff44,
            -142px 61.3333333333px #001eff,
            64px -139.6666666667px #84ff00,
            -8px -95.6666666667px #6aff00,
            -188px -110.6666666667px #ff00ea,
            234px -139.6666666667px #5900ff,
            18px -274.6666666667px #00aeff,
            -138px -277.6666666667px #ffdd00,
            41px 70.3333333333px #ff0051,
            -221px -318.6666666667px #00b3ff,
            174px -94.6666666667px #62ff00,
            76px -47.6666666667px #b7ff00,
            208px -102.6666666667px #0099ff,
            -173px -249.6666666667px #ff007b,
            -19px -365.6666666667px #0040ff,
            -249px 8.3333333333px #ff00aa,
            187px -46.6666666667px #ff8400,
            -56px -405.6666666667px #ff00ee,
            -91px 4.3333333333px #ffa200,
            77px -347.6666666667px #00ffaa,
            5px -387.6666666667px #ff001a,
            -132px -33.6666666667px #0091ff,
            -155px -125.6666666667px #6200ff,
            -90px -99.6666666667px #1100ff,
            36px -319.6666666667px #00ff5e,
            78px -247.6666666667px #0015ff,
            -71px -286.6666666667px #0011ff,
            92px -109.6666666667px #ffa200,
            -125px -97.6666666667px #f700ff,
            -140px -342.6666666667px #00ff2b,
            42px -239.6666666667px #9d00ff,
            73px -52.6666666667px #e1ff00,
            -224px -85.6666666667px red,
            -193px -12.6666666667px #d900ff,
            -5px -217.6666666667px #4400ff,
            -9px -19.6666666667px #1500ff,
            -238px -338.6666666667px #8cff00,
            -230px -353.6666666667px #ff5100,
            32px -194.6666666667px #ff3300,
            25px -142.6666666667px #0900ff,
            78px -395.6666666667px #ff4d00,
            -170px -206.6666666667px #00bbff,
            -96px -191.6666666667px #7700ff,
            135px 62.3333333333px #ff0099,
            -64px -315.6666666667px #ffe600,
            -186px -3.6666666667px #00ffa6,
            117px -254.6666666667px #ff5500,
            -248px -74.6666666667px #c800ff;
    }
}
@-o-keyframes bang {
    to {
        box-shadow:
            -243px -258.6666666667px #fffb00,
            -11px -306.6666666667px #ff7b00,
            130px -92.6666666667px #00fff2,
            45px -356.6666666667px #00ff44,
            -142px 61.3333333333px #001eff,
            64px -139.6666666667px #84ff00,
            -8px -95.6666666667px #6aff00,
            -188px -110.6666666667px #ff00ea,
            234px -139.6666666667px #5900ff,
            18px -274.6666666667px #00aeff,
            -138px -277.6666666667px #ffdd00,
            41px 70.3333333333px #ff0051,
            -221px -318.6666666667px #00b3ff,
            174px -94.6666666667px #62ff00,
            76px -47.6666666667px #b7ff00,
            208px -102.6666666667px #0099ff,
            -173px -249.6666666667px #ff007b,
            -19px -365.6666666667px #0040ff,
            -249px 8.3333333333px #ff00aa,
            187px -46.6666666667px #ff8400,
            -56px -405.6666666667px #ff00ee,
            -91px 4.3333333333px #ffa200,
            77px -347.6666666667px #00ffaa,
            5px -387.6666666667px #ff001a,
            -132px -33.6666666667px #0091ff,
            -155px -125.6666666667px #6200ff,
            -90px -99.6666666667px #1100ff,
            36px -319.6666666667px #00ff5e,
            78px -247.6666666667px #0015ff,
            -71px -286.6666666667px #0011ff,
            92px -109.6666666667px #ffa200,
            -125px -97.6666666667px #f700ff,
            -140px -342.6666666667px #00ff2b,
            42px -239.6666666667px #9d00ff,
            73px -52.6666666667px #e1ff00,
            -224px -85.6666666667px red,
            -193px -12.6666666667px #d900ff,
            -5px -217.6666666667px #4400ff,
            -9px -19.6666666667px #1500ff,
            -238px -338.6666666667px #8cff00,
            -230px -353.6666666667px #ff5100,
            32px -194.6666666667px #ff3300,
            25px -142.6666666667px #0900ff,
            78px -395.6666666667px #ff4d00,
            -170px -206.6666666667px #00bbff,
            -96px -191.6666666667px #7700ff,
            135px 62.3333333333px #ff0099,
            -64px -315.6666666667px #ffe600,
            -186px -3.6666666667px #00ffa6,
            117px -254.6666666667px #ff5500,
            -248px -74.6666666667px #c800ff;
    }
}
@-ms-keyframes bang {
    to {
        box-shadow:
            -243px -258.6666666667px #fffb00,
            -11px -306.6666666667px #ff7b00,
            130px -92.6666666667px #00fff2,
            45px -356.6666666667px #00ff44,
            -142px 61.3333333333px #001eff,
            64px -139.6666666667px #84ff00,
            -8px -95.6666666667px #6aff00,
            -188px -110.6666666667px #ff00ea,
            234px -139.6666666667px #5900ff,
            18px -274.6666666667px #00aeff,
            -138px -277.6666666667px #ffdd00,
            41px 70.3333333333px #ff0051,
            -221px -318.6666666667px #00b3ff,
            174px -94.6666666667px #62ff00,
            76px -47.6666666667px #b7ff00,
            208px -102.6666666667px #0099ff,
            -173px -249.6666666667px #ff007b,
            -19px -365.6666666667px #0040ff,
            -249px 8.3333333333px #ff00aa,
            187px -46.6666666667px #ff8400,
            -56px -405.6666666667px #ff00ee,
            -91px 4.3333333333px #ffa200,
            77px -347.6666666667px #00ffaa,
            5px -387.6666666667px #ff001a,
            -132px -33.6666666667px #0091ff,
            -155px -125.6666666667px #6200ff,
            -90px -99.6666666667px #1100ff,
            36px -319.6666666667px #00ff5e,
            78px -247.6666666667px #0015ff,
            -71px -286.6666666667px #0011ff,
            92px -109.6666666667px #ffa200,
            -125px -97.6666666667px #f700ff,
            -140px -342.6666666667px #00ff2b,
            42px -239.6666666667px #9d00ff,
            73px -52.6666666667px #e1ff00,
            -224px -85.6666666667px red,
            -193px -12.6666666667px #d900ff,
            -5px -217.6666666667px #4400ff,
            -9px -19.6666666667px #1500ff,
            -238px -338.6666666667px #8cff00,
            -230px -353.6666666667px #ff5100,
            32px -194.6666666667px #ff3300,
            25px -142.6666666667px #0900ff,
            78px -395.6666666667px #ff4d00,
            -170px -206.6666666667px #00bbff,
            -96px -191.6666666667px #7700ff,
            135px 62.3333333333px #ff0099,
            -64px -315.6666666667px #ffe600,
            -186px -3.6666666667px #00ffa6,
            117px -254.6666666667px #ff5500,
            -248px -74.6666666667px #c800ff;
    }
}
@keyframes bang {
    to {
        box-shadow:
            -243px -258.6666666667px #fffb00,
            -11px -306.6666666667px #ff7b00,
            130px -92.6666666667px #00fff2,
            45px -356.6666666667px #00ff44,
            -142px 61.3333333333px #001eff,
            64px -139.6666666667px #84ff00,
            -8px -95.6666666667px #6aff00,
            -188px -110.6666666667px #ff00ea,
            234px -139.6666666667px #5900ff,
            18px -274.6666666667px #00aeff,
            -138px -277.6666666667px #ffdd00,
            41px 70.3333333333px #ff0051,
            -221px -318.6666666667px #00b3ff,
            174px -94.6666666667px #62ff00,
            76px -47.6666666667px #b7ff00,
            208px -102.6666666667px #0099ff,
            -173px -249.6666666667px #ff007b,
            -19px -365.6666666667px #0040ff,
            -249px 8.3333333333px #ff00aa,
            187px -46.6666666667px #ff8400,
            -56px -405.6666666667px #ff00ee,
            -91px 4.3333333333px #ffa200,
            77px -347.6666666667px #00ffaa,
            5px -387.6666666667px #ff001a,
            -132px -33.6666666667px #0091ff,
            -155px -125.6666666667px #6200ff,
            -90px -99.6666666667px #1100ff,
            36px -319.6666666667px #00ff5e,
            78px -247.6666666667px #0015ff,
            -71px -286.6666666667px #0011ff,
            92px -109.6666666667px #ffa200,
            -125px -97.6666666667px #f700ff,
            -140px -342.6666666667px #00ff2b,
            42px -239.6666666667px #9d00ff,
            73px -52.6666666667px #e1ff00,
            -224px -85.6666666667px red,
            -193px -12.6666666667px #d900ff,
            -5px -217.6666666667px #4400ff,
            -9px -19.6666666667px #1500ff,
            -238px -338.6666666667px #8cff00,
            -230px -353.6666666667px #ff5100,
            32px -194.6666666667px #ff3300,
            25px -142.6666666667px #0900ff,
            78px -395.6666666667px #ff4d00,
            -170px -206.6666666667px #00bbff,
            -96px -191.6666666667px #7700ff,
            135px 62.3333333333px #ff0099,
            -64px -315.6666666667px #ffe600,
            -186px -3.6666666667px #00ffa6,
            117px -254.6666666667px #ff5500,
            -248px -74.6666666667px #c800ff;
    }
}
@-webkit-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}
@-moz-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}
@-o-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}
@-ms-keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}
@keyframes gravity {
    to {
        transform: translateY(200px);
        -moz-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -o-transform: translateY(200px);
        -ms-transform: translateY(200px);
        opacity: 0;
    }
}
@-webkit-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
@-moz-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
@-o-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
@-ms-keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
@keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .no-underline {
        text-decoration: none;
        text-decoration-line: none;
    }
}
